.fontRoboto {
    font-family: Roboto;
}
/* -------------------------------------
   Receipt styles
------------------------------------- */

.invoice {
    margin: 25px auto;
    text-align: left;
    width: 100%;
    font-size: small;
}
.invoice td {
    padding: 5px 0;
}
.invoice .invoiceItems {
    width: 100%;
}
.invoice .invoiceItems td {
    border-top: #eee 1px solid;
}
.invoice .invoiceItems .total td {
    border-top: 2px solid #333;
    border-bottom: 2px solid #333;
    font-weight: 700;
    font-size: large;
}
.termAndConditionsButton{
  padding-top: 0em !important;
  padding-left: 0em !important;
}
