.root {
  background-color: white;
}

.container {
  height: auto;
  font-family: Roboto !important;
  margin: auto;
  max-width: 2500px;
}

.headerHW {
  max-width: 80%;
}

.bodyHW {
  min-height: 91vh;
}

.letusLogoImage {
  max-height: 47px;
  max-width: 154px;
}

@media (max-width: 48em) {
  .headerHW {
      max-width: 90%;
  }
}
