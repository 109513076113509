.containerCustom {
  max-width: 100%;
}

.customRow:nth-of-type(n) {
  padding: 2% 18%;
  display: flex;
  justify-content: center;
}

.customRow:nth-of-type(even) {
  background-color: #f8f9fc;
}

.btnGetStarted {
  padding: 15px 25px;
  color: white;
  background-color: #33a800;
  font-family: Roboto !important;
  font-size: 18px;
}

.payeeImg, .payeeHeroImg, .payeeUseForImg {
  max-width: 85%;
  height: auto;
}

.payeeUseForImg {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.payNowImg {
  max-width: 80%;
  height: auto;
}

.payLaterImg {
  max-width: 95%;
  height: auto;
}

.heroHeading,
.heading {
  font-family: Montserrat !important;
  font-size: clamp(2rem, 2vw, 8rem);
  font-weight: 600;
}

.heading, .subHeading {
  text-align: center;
  width: 100%;
}

.subHeading {
  font-family: Montserrat !important;
  font-size: clamp(1rem, 1vw, 4rem);
  font-weight: 600;
  color: #520070;
}

.headingRow {
  margin: auto;
  width: 100%;
}

.heroInfo {
  font-family: Roboto !important;
  font-size: clamp(1rem, 1vw, 5rem);
}

.whatYouNeedCard {
  width: 80%;
  max-width: 750px;
  padding: 6% 8%;
  margin: auto;
}

.sectionPaymentMethods,
.sectionHero {
  width: 95% !important;
}

.subSectionRightRow {
  width: 50%;
  margin: auto;
}

@media (min-width: 1600px) {
  .payeeHeroImg, payeeUseForImg {
    max-width: 75%;
  }
}

@media (max-width: 1400px) {
  .sectionPaymentMethods,
  .sectionHero {
    width: 100% !important;
  }
}

@media (max-width: 1200px) {
  .customRow:nth-of-type(n) {
    padding: 3% 10%;
  }
}

@media (max-width: 900px) {
  .customRow:nth-of-type(n) {
    padding: 0% 3%;
  }
  .btnGetStarted {
    margin-top: 0em !important;
    font-size: 16px;
  }
  .hwCardCol {
    margin-right: 1rem !important;
  }
  .whatYouNeedCardRow {
    min-height: 65vh !important;
  }
  .subSectionRightRow {
    width: 70%;
    margin: unset;
  }
  .payeeUseForImg {
    width: 60%;
    height: auto;
  }
}

@media (max-width: 650px) {
  .whatYouNeedCard {
    width: 98%;
    padding: 4% 8%;
  }
}
