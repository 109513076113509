.containerCustom {
    max-width: 80%;
    height: 100%;
    padding-top: 3%;
}

.containerFormHW {
    width: 80%;
    padding-top: 2%;
    margin: auto;
}

.containerFormHW label {
    margin-bottom: 0.1rem !important;
}

.containerFormHW label:not(:first-of-type) {
    margin-top: 1rem !important;
}

.stepButtonHW {
    border-radius: 0em;
    min-width: 150px;
    border-radius: 5px;
    max-height: 44px;
}

.addPropertyButtonHW {
    background-color: #520070;
    color: #FFFFFF;
    border-radius: 1em;
}

.payNowButtonHW {
    background-color: #cd73ee4f;
    color: #520070;
}

.payLaterButtonHW {
    background-color: #FF9900A6;
    color: #373737;
}

.amountInput {
    text-align: right;
}

.stepper {
    width: 80%;
    margin: auto;
}

.fontRoboto {
    font-family: Roboto;
}

@media (max-width: 60em) {
    .receiptFooterCol {
        max-width: 100%;
        flex: 0 0 100%;
    }
}

@media (max-width: 48em) {
    .stepper {
        width: 100%;
        margin: auto;
    }
    .stepperLabel {
        font-size: 0.8em;
        white-space: pre-line;
    }
    .containerFormHW {
        width: 100%;
        margin: auto;
    }
    .containerCustom {
        max-width: 90%;
    }
}

/* HyperWallet Card */
.hwCard {
    min-width: 18rem;
    max-width: 18rem;
    min-height: 18rem;
    max-height: 18rem;
    border-radius: 20px;
    border: none;
}
.hwCardMd {
    min-width: 22rem;
    max-width: 22rem;
    min-height: 22rem;
    max-height: 22rem;
    border: none;
}
.hwCardImg {
    height: auto;
    width: 25%;
    margin: auto;
}
.hwCardPayee {
    min-width: 20rem;
    max-width: 20rem;
    min-height: 21rem;
    max-height: 21rem;
    border-radius: 20px;
    border-color: rgb(234, 234, 234);
}