.messageBubble {
    width: 400px;
    padding: 8px;
    border-radius: 8px;
}
.receivedMessageBubble {
    background-color: #ededed;
}
.sentMessageBubble {
    background-color: #e4ffda;
}
.message {
    font-size: 14px;
}