.containerCustom {
  max-width: 80%;
  margin-top: 4.1vh;
}

.mainRowContainer {
    height: 100%;
    min-height: 70vh;
}

.colContainerLeft {
    display: flex;
    justify-content: center;
    align-items: center; 
}

.colContainerLeft span {
    font-size: 5.2vmin;
    font-weight: bolder;
    color: #33A800;
}

.colContainerLeft p {
    font-size: 2.2vmin;
    color: #696F79;
}

.colContainerLeft input {
    border: none;
    padding: 10px 50px;
    background-color: #33A800;
    color: white;
    font-size: 2vmin;
}

.colContainerRight {
    display: flex;
    justify-content: center;
    align-items: center; 
}

.colInnerLeft {
    height: 70%;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center; 

}
.colInnerRight {
    height: 70%;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.colInnerRight img {
    max-width: 100%;
    max-height: 100%;
}

.landingPageHeading{
    font-family: Montserrat !important;
}

.landingPageText{
    color: #0F0F3D;
    font-size: 1.1rem;
    font-family: Roboto !important;
}

.landingPageChargeSelectionLabel{
    color: #0F0F3D;
    font-size: 1.1rem;
}

.mobileLogo {
    max-width: 50% !important;
}

.beginBtn{
    border: none;
    padding: 10px 100px;
    background-color: #33A800;
    color: white;
    font-size: 2vmin;
}

.beginList {
    padding: 15px 40px;
}

@media (max-width: 48em) {
    .colContainerLeft {
        width: 100%;
    }
    .colContainerRight {
        display: none;
    }
    .colContainerLeft p {
        font-size: 3vmin;
    }
    .colContainerLeft input {
        font-size: unset;
    }
    .colContainerLeft input {
        font-size: 3vmin;
        margin-top: 15px !important;
    }
    .containerCustom {
        max-width: 90%;
    }
    .beginBtn{
        font-size: unset;
    }
    .beginList {
        padding: 0px;
    }
}

@media (max-width: 600px) {
    .colContainerLeft span {
        font-size: 6.5vmin;
    }
    .colContainerLeft p {
        font-size: 4.5vmin;
    }
    .colContainerLeft input {
        font-size: 4.5vmin;
        margin-top: 10px !important;
    }
}
