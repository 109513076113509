.textMessageBody {
    min-height: 400px;
    max-height: 550px;


}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #999;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #777;
}