.containerCustom {
    max-width: none;
    background-image: url("../../media/img/backgrounds/midwest.jpg");
    background-size: cover;
    background-position: center;
}

.colInfo {
    display: flex;
    justify-content: center;
    color: white;
    background-color: #432767;
    font-family: Montserrat !important;

    section {
        padding: 10% 8%;
        h1 {
            font-family: Montserrat !important;
            font-size: clamp(2rem, 2.5vw, 4rem);
        }
        h4 {
            font-family: Montserrat !important;
        }
        ul {
            list-style: none;

            li {
                padding: 1% 0;
            }
        }
        ul li:before {
            content: '✔';
            color: #51c15c;
            font-weight: bolder;
            font-size: 22px;
            margin-right: 5px;
        }
        ol {
            li {
                padding: 1% 0;
            }
        }
        ol li::marker {
            color: #51c15c;
            font-weight: bolder;
            font-size: 22px;
            margin-right: 5px;
        }
    }
}

.colLogin {
    padding: 4% 8%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../media/img/backgrounds/building.jpeg");
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
}

.offerBubble {
    height: 150px;
    width: 150px;
    float: right;
    border-radius: 50%;
    background-color: #51c15c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    span {
        font-size: clamp(18px, 1.5vw, 20px);
        text-align: center;
        font-weight: bolder;
    }
}