.navCustom {
  font-size:  1rem;
  min-height: 9vh;
  z-index: 999;
  background-color: white !important;
  box-shadow: 0px 2px 4px #b3b3b3;
}

.navSec1 {
  justify-content: start;
  padding: 10px 0;
}

.navSec1 li{
  padding-right: 40px;
}

.navSec2 {
  text-align: center;
}

.navSec2 img {
  max-width: 100%;
  height: auto;
}

.navSec3 {
  justify-content: end;
  padding: 10px 0;
}


.navSec3 li{
  padding-left: 40px;
}

.containerCustom {
  max-width: 80%;
}

.rowCustom {
  width: 100%;
  align-items: center;
}

.btnGetStarted {
  background-color: #520070;
  padding: 8px 20px;
  color: white;
}

.mobileLogo {
  display: none;
}

.navText {
  color: black !important;
  font-weight: 500;
  font-family: Roboto !important;
}

@media (min-width: 992px) {
  .navText {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media (max-width: 992px) {
  .navSec2Container {
    display: none;
  }

  .mobileLogo {
    display: block;
  }

  .navSec1,
  .navSec3 {
    padding: 0;
  }

  .navSec3 li{
    padding-left: 0 !important;
  }
}

@media (max-width: 1200px) {
  .navSec2 img {
    padding: 10px 0px;
  }
}

@media (max-width: 1400px) {
  .containerCustom {
    max-width: 100%;
  }
  .navSec1 li{
    padding-right: 30px;
  }
  .navSec3 li{
    padding-left: 30px;
  }
}