.containerCustom {
    max-width: 100%;
}

.payeeHero, .payerHero{
    width: 75%;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 2% 0%;
}

.payerHero {
    min-height: 30vh;
}

.payerImg {
    float: right;
}

.payeeHero {
    min-height: 45vh;
}

.payeeImg {
    float: right;
}

.payeeHeading, .payerHeading, .faqHeading{
    font-family: Montserrat !important;
    font-size: 32px;
    font-weight: 700;
}

.faqQues{
    font-family: Montserrat !important;
    font-size: 18px;
    font-weight: 700;
}

.faqAns, .hwExternalHelp{
    font-family: Roboto !important;
    font-size: 18px;
}

.hwExternalHelp{
    color: navy !important;
    font-weight: 500;
}

.faqAccordionRoot {
    background-color: #F8F9FC;
}

.faqAccordionContainer {
    min-height: 51vh;
    width: 75%;
    margin: auto;
    padding: 2% 0%;
}

.faqAccordionHeading {
    display: flex;
    justify-content: space-between;
}

.faqFooter {
    display: flex;
    justify-content: center;
}

.btnGetStarted,
.btnGetHelp {
    padding: 5% 80px;
    color: white;
}

.btnGetStarted {
    background-color: #33A800;
}

.btnGetHelp {
    background-color: #520070;
}



@media (max-width: 900px) {

    .payerHero,
    .payeeHero {
        min-height: 30vh;
        padding: 4% 0%;
    }

    .btnGetStarted {
        margin-right: 0px !important;
        margin-bottom: 10px;
    }

    .btnGetStarted,
    .btnGetHelp {
        padding: 5% 50px;
    }

    .faqHeading{
        font-size: 25px;
    }
}